import { Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import Loader from "react-loaders";

import { ToastContainer } from "react-toastify";
import { config } from "../../config";

const Workspace = lazy(() => import("../../Pages/Workspace"));
const History = lazy(() => import("../../Pages/History"));
const Analytics = lazy(() => import("../../Pages/Analytics"));
const Settings = lazy(() => import("../../Pages/Settings"));
const Assets = lazy(() => import("../../Pages/Assets"));
const Admin = lazy(() => import("../../Pages/Admin"));
const Auth = lazy(() => import("../../Pages/Auth"));
const Campaigns = lazy(() => import("../../Pages/Workspace/Campaigns"));
export const AppPublic = () => {
  return (
    <Fragment>
      {/* Auth Pages */}
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-grid-cy" />
              </div>
              <h6 className="mt-3">
                Getting things ready ...
                <small>We like to put our wheels in motion for you!</small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/auth" component={Auth} />
      </Suspense>

      {/* Default route */}

      <Route exact path="/" render={() => <Redirect to="/auth/login" />} />
      <ToastContainer />
    </Fragment>
  );
};

const AppMain = () => {
  return (
    <Fragment>
      {/* Meetings */}
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-grid-cy" />
              </div>
              <h6 className="mt-3">
                Getting things ready ...
                <small>We like to put our wheels in motion for you!</small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/workspace" component={Workspace} />
      </Suspense>

      {/* History */}

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-grid-cy" />
              </div>
              <h6 className="mt-3">
                Getting things ready ...
                <small>We like to put our wheels in motion for you!</small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/history" component={History} />
      </Suspense>

      {/* Campaigns */}

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-grid-cy" />
              </div>
              <h6 className="mt-3">
                Getting things ready ...
                <small>We like to put our wheels in motion for you!</small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/campaigns" component={Campaigns} />
      </Suspense>

      {/* Analytics */}

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-grid-cy" />
              </div>
              <h6 className="mt-3">
                Getting things ready ...
                <small>We like to put our wheels in motion for you!</small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/analytics" component={Analytics} />
      </Suspense>

      {/* Settings */}

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-grid-cy" />
              </div>
              <h6 className="mt-3">
                Getting things ready ...
                <small>We like to put our wheels in motion for you!</small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/settings" component={Settings} />
      </Suspense>

      {/* Assets */}

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-grid-cy" />
              </div>
              <h6 className="mt-3">
                Getting things ready ...
                <small>We like to put our wheels in motion for you!</small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/assets" component={Assets} />
      </Suspense>

      {/* Admin */}

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-grid-cy" />
              </div>
              <h6 className="mt-3">
                Getting things ready ...
                <small>We like to put our wheels in motion for you!</small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/admin" component={Admin} />
      </Suspense>

      {/* Auth Pages */}

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-grid-cy" />
              </div>
              <h6 className="mt-3">
                Getting things ready ...
                <small>We like to put our wheels in motion for you!</small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/auth" component={Auth} />
      </Suspense>

      {/* Default route */}

      <Route
        exact
        path="/auth/login"
        render={() => <Redirect to={config.landing_page} />}
      />
      <Route
        exact
        path="/auth/phone_login"
        render={() => <Redirect to={config.landing_page} />}
      />
      <ToastContainer />
    </Fragment>
  );
};

export default AppMain;
